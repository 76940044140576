body {
    margin: 0;
}

.dropbtn {
    color: #716f6f;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    font-size: 0.8rem;
    display: block;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    -webkit-box-shadow: 0px 0px 40px 4px rgba(194,192,194,1);
    -moz-box-shadow: 0px 0px 40px 4px rgba(194,192,194,1);
    box-shadow: 0px 0px 40px 4px rgba(194,192,194,1);
    z-index: 1;
    top: 2rem;
    transition: 1s;
    -moz-transition: 1s;
}

.arrow {
    margin: 0.2rem;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: #716f6f;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a img {
    margin-right: 1rem;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    color: #161517;
}

/* Show the dropdown menu on hover */
/*.dropdown:hover .dropdown-content {*/
/*    display: block;*/
/*    transition: 1s;*/
/*    -moz-transition: 1s;*/
/*}*/

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    color: #161517;
}

.nav-items {
    display: flex;
}

.nav-collapse-items {
    display: flex;
    flex: 8;
}

.logo-item {
    flex: 1;
    margin: 1.5rem;
    color: #716f6f;
}

.list-items {
    color: #716f6f;
    flex: 4;
    display: flex;
    gap: 3rem;
    margin: 1.5rem 0.2rem;
}

.hamburger {
    border: none;
    background: white;
    font-size: 1.5rem;
    display: none;
}

.cross {
    display: none;
}

.user-items {
    display: flex;
    gap: 0.5rem;
    flex: 1;
}

.loginBtn {
    background: white;
    border: 1px solid transparent;
    padding: 0.5rem;
    margin: 1rem;
    font-size: 1rem;
    color: #716f6f;
    cursor: pointer;
    font-family: 'Epilogue', sans-serif;
}

.loginBtn:hover {
    color: #161517;
}

.registerBtn {
    font-family: 'Epilogue', sans-serif;
    margin: 1rem 1.5rem 1rem 1rem;
    font-size: 1rem;
    border: 1px solid #716f6f;
    background: white;
    padding: 0.5rem 1.2rem;
    border-radius: 0.8rem;
    color: #716f6f;
    cursor: pointer;
}

.registerBtn:hover {
    color: #161517;
    border: 1px solid #161517;
}

.features-item {
    cursor: pointer;
}

.features-item:hover {
    color: #161517;
}

@media screen and (max-width: 375px) {

    .hamburger {
        display: block;
        margin-right: 1rem;
        padding-bottom: 0.5rem;
    }

    .nav-collapse-items {
        display: none;
    }

    .collapsed-items {
        display: flex;
        flex-direction: column;
        position: absolute;
        background: white;
        right: 0;
        width: 60%;
        height: 100%;
        font-size: 0.8rem;
    }

    .list-items {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        margin: 1rem;
    }

    .user-items {
        display: flex;
        flex-direction: column;
        flex: 20;
        align-items: center;
        font-size: 0.8rem;
    }

    .dropdown-content {
        position: relative;
        box-shadow: none;
        background: none;
        top: 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 0.7rem;
        align-items: center;
        justify-content: center;
        margin: 0.4rem;
        margin-top: 1rem;
    }

    .dropdown-content a {
        padding: 0;
    }

    .loginBtn {
        background: none;
        margin: 0.5rem 0 0 0;
        padding: 0;
        font-size: 0.8rem;
        font-family: 'Epilogue', sans-serif;
    }

    .registerBtn {
        margin: 0;
        padding: 0.4rem 4.5rem;
        font-size: 0.8rem;
        font-family: 'Epilogue', sans-serif;
    }

    .cross {
        display: block;
        background: transparent;
        border: none;
        text-align: end;
        font-size: 1.3rem;
        margin: 1rem 1rem 0 0;
    }

}